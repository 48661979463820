
import FullCal from "@/components/FullCal.vue";
import ScheduleWidget from "@/components/Calendar/ScheduleWidget.vue";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {defineComponent, ref} from "vue";
import {CallService} from "@/services/CallService";
import {ConflictService} from "@/services/ConflictService";
import {getColorName} from "@/models/scheduling/CallType";
import {bus} from "@/modules/eventBus";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useToast} from "vue-toastification";
import {isPlatform} from "@ionic/vue";
import moment from "moment";

export default defineComponent({
  name: "CalendarTab",
  components: {ScheduleWidget, FullCal},
  setup() {
    const calendar = ref<InstanceType<typeof FullCal>>();
    const reFetchEvents = () => {
      calendar.value?.redraw();
    }
    const toast = useToast();
    return {calendar, reFetchEvents, toast}
  },
  data() {
    return {
      calls: [] as Array<CallCalendarEvent>,
      conflicts: [] as Array<CallCalendarEvent>,
      showConflicts: false,
      showEntities: [],
      filterChanged: false,
      conflictFilterChanged: false,
      filters: {
        callTypes: [] as Array<string>,
        showFor: '',
        showConflicts: true
      },
      widgetLoading: false,
      activeStart: 'This Month'
    }
  },
  methods: {
    fetchAccountCalls(fetchInfo, successCallback, failureCallback) {
      if (this.filterChanged) {
        this.filterChanged = false
        let callTypeSet = new Set(this.filters.callTypes as Array<string>)
        successCallback(this.calls.filter(call => {
          if (callTypeSet.has(call.callTypeId)) {
            return call
          }
        }))
      } else {
        CallService.fetchAccountCalls(fetchInfo.startStr, fetchInfo.endStr).then(response => {
          this.calls = response.data
          this.calls.forEach(call => {
            call.classNames = `fc-h-${this.getCallTypeColor(call.callTypeColor)}-event`
          })
          successCallback(response.data)
          this.emitCalls()
        }).catch((err) => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
          failureCallback(err)
        })
      }
    },
    fetchAccountConflicts(fetchInfo, successCallback, failureCallback) {
      if (this.conflictFilterChanged) {
        this.conflictFilterChanged = false
        successCallback(this.conflicts.filter(conflict => {
          if (conflict.isConflict && this.filters.showConflicts) {
            return conflict
          }
        }))
      } else {
        ConflictService.listAsCalendar(fetchInfo.startStr, fetchInfo.endStr).then(response => {
          this.conflicts = response.data
          this.conflicts.forEach(conflict => {
            conflict.display = 'list-item'
          })
          successCallback(response.data)
          this.emitConflicts()
        }).catch((err) => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
          failureCallback(err)
        })
      }
    },
    emitCalls() {
      bus.emit('calendar:filters:update', this.calls)
    },
    emitConflicts() {
      bus.emit('calendar:filters:update:conflicts', this.conflicts)
    },
    getCallTypeColor(color: number) {
      return getColorName(color);
    },
    invokeAddConflict() {
      bus.emit('modal:conflicts:add')
    },
    fetchForWidget() {
      const start = moment().startOf('day');
      const end = moment().add(8, "days").endOf('day');
      CallService.fetchAccountCalls(start.format(), end.format()).then(response => {
        this.calls = response.data
        this.widgetLoading = false
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.widgetLoading = false
      })
      ConflictService.listAsCalendar(start.format(), end.format()).then(response => {
        this.conflicts = response.data
        this.conflicts.forEach(conflict => {
          conflict.display = 'auto'
        })
        this.emitConflicts()
      }).catch((err) => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      })
    },
    navigateToConflicts(){
      this.$router.push({name: 'user-settings-conflicts'})
    },
    previous() {
      bus.emit('calendar:actions:previous')
    },
    next() {
      bus.emit('calendar:actions:next')
    },
    today() {
      bus.emit('calendar:actions:today')
    },
    setActiveStart(activeStart: string) {
      this.activeStart = activeStart
    },
  },
  computed: {
    displayEvents(): Array<CallCalendarEvent> {
      let events = [] as Array<CallCalendarEvent>
      events = events.concat(this.calls)
      if (this.showConflicts) {
        events = events.concat(this.conflicts)
      }
      return events
    },
    availableProductions(): Array<string> {
      return [...Array.from(new Set(this.calls.map(event => event.productionName)))]
    },
    availableCallTypes(): Array<string> {
      return [...Array.from(new Set(this.calls.map(event => event.callTypeName)))]
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    isCapacitor() {
      return isPlatform('capacitor')
    }
  },
  mounted() {
    bus.on('calendar:filters:change', (filters) => {
      // console.log('filters changed calendar tab', filters)
      this.filterChanged = true
      this.filters = filters as any
      this.reFetchEvents()
    })
    bus.on('calendar:filters:change:conflicts', (showConflicts) => {
      // console.log('conflicts filter changed scheduling tab', showConflicts)
      this.conflictFilterChanged = true
      this.filters.showConflicts = showConflicts as boolean
      this.reFetchEvents()
    })
    bus.on('calendar:update:activeStart', (activeStart) => this.setActiveStart(activeStart as string))
    this.fetchForWidget()
  }
})
